<template>
  <div>
    <Navbar class="nav-z-idx"/>
    <div class="container-v3">
      <div class="center-content-v3">
        <div class="maintenance-v3">
          <img src="@/assets/images/wg-not-found.svg" alt="">
          <p class="title-wg-not-found-v3">The workgroup is no longer available</p>
          <p class="sub-wg-not-found-v3">It has been deleted or you’re no longer have access to it. </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../../../components/nav-login-registerV3.vue";

export default {
  components: {
    Navbar
  }
}
</script>

<style>
.title-wg-not-found-v3{
    margin: 8px 0;
    font-family: 'Plus Jakarta Sans';
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    letter-spacing: -0.02em;
    color: #262A2C;
}
.sub-wg-not-found-v3{
    margin: 0;
    font-family: 'Plus Jakarta Sans';
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: -0.02em;
    color: #919394;
}
</style>
